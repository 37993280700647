import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import SignInContainer from 'components/SignInContainer/SignInContainer';

const SignInPage = ({ data, location }) => {
  return (
    <Layout location={location} type="plain">
      <SEO meta={data.signInYaml.metaTags} title={data.signInYaml.title} />
      <SignInContainer data={data.signInYaml.signInContainer} />
      <ZipGateContainer location={location} />
    </Layout>
  );
};

export default SignInPage;

export const pageQuery = graphql`
  query SignInPageQuery {
    signInYaml {
      id
      title
      metaTags {
        name
        content
      }

      signInContainer {
        heroImage {
          childImageSharp {
            fluid(maxWidth: 925) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        safeGuardSignInLink
        safeSecuritySignInLink
        text
        submitTitle
        footerText
      }
    }
  }
`;
