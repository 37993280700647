import React, { Component } from 'react';
import { Form } from 'react-final-form';
import format from 'string-template';
import moment from 'moment-timezone';
import Img from 'gatsby-image';
import { PrimaryButton } from 'components/Shared/Button/Button';
import Link from 'components/Shared/Link/Link';
import FormField, {
  FIELD_TYPES,
} from 'components/Shared/Form/FormField/FormField';
import Validators, {
  composeValidators,
} from 'components/Shared/Form/validators';
import CustomerApi from 'components/Shared/CustomerApi';
import logo from 'static/images/header/aaa-smart-home-logo.svg';
import './SignInContainer.scss';

class SignInContainer extends Component {
  constructor() {
    super();
    this.validators = {
      username: composeValidators(
        Validators.required({
          default: 'Please enter your username or account id',
        })
      ),
    };
  }

  submit = async values => {
    const { data } = this.props;
    const errorMessages = {
      notfound: 'Customer not found',
      failed: 'Unable to check customer',
    };
    try {
      const response = await CustomerApi.checkCustomer(values.username);
      if (response.project === 'safesecurity') {
        return window.location.replace(
          `${data.safeSecuritySignInLink}?username=${encodeURIComponent(
            values.username
          )}`
        );
      }
      window.location.replace(
        `${data.safeGuardSignInLink}?username=${encodeURIComponent(
          values.username
        )}`
      );
    } catch (err) {
      if (err.status === 404) {
        return { username: errorMessages.notfound };
      }
      window.Sentry.captureException(err);
      return { username: errorMessages.failed };
    }
  };

  render() {
    const { data } = this.props;
    const validators = this.validators;
    return (
      <div className="aaa-signin">
        <div className="aaa-signin__image-container">
          <Img
            className="aaa-signin__hero-image"
            fluid={data.heroImage.childImageSharp.fluid}
          />
          <div className="aaa-signin__image-overlay" />
        </div>
        <div className="aaa-signin__main-container">
          <div className="aaa-signin__container">
            <div className="aaa-signin__header">
              <Link to="/">
                <img src={logo} className="aaa-signin__logo" />
              </Link>
            </div>
            <div className="aaa-signin__form-container">
              <h1 className="aaa-signin__title" 
                dangerouslySetInnerHTML={{ __html: data.title }}
              />
              <Form
                decorators={this.decorators}
                onSubmit={this.submit}
                render={({ handleSubmit, submitting }) => (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <FormField
                        data={{
                          name: 'username',
                          placeholder: 'Username',
                          type: FIELD_TYPES.text,
                        }}
                        validate={validators.username}
                      />
                    </div>
                    <div>
                      <PrimaryButton
                        className="aaa-signin__button"
                        type="submit"
                        disabled={submitting ? 'disabled' : ''}
                      >
                        {data.submitTitle}
                      </PrimaryButton>
                    </div>
                  </form>
                )}
              />
              <div
                className="aaa-signin__section-text"
                dangerouslySetInnerHTML={{ __html: data.text }}
              />
            </div>
            <div className="aaa-signin__footer">
              <div
                className="aaa-signin__footer-text"
                dangerouslySetInnerHTML={{
                  __html: format(data.footerText, {
                    year: moment().format('YYYY'),
                  }),
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignInContainer;
