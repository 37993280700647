import {
  API_BASE_URL,
  API_CUSTOMER_CHECK_PATH,
} from 'components/Shared/Constants';
import Api from 'components/Shared/Api';

class CustomerApiService {
  constructor(baseUrl) {
    this.api = new Api(baseUrl);
  }

  checkCustomer = async (customer) => {
    return await this.api.post(API_CUSTOMER_CHECK_PATH, {
      customer
    });
  };

}
  
const ZipApi = new CustomerApiService(API_BASE_URL);
  
export default ZipApi;
